<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> CREATE CABANG</v-card-title>
        <form @submit.prevent="CreateData" ref="form">
          <v-row>
            <v-col cols="12" v-for="error in errors" :key="error">
              <v-alert color="error" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    <!-- {{ icons.mdiAlertOutline }} -->
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">{{ error }}</p>
                    <!-- <a href="javascript:void(0)" class="text-decoration-none warning--text">
                    <span class="text-sm">Resend Confirmation</span>
                  </a> -->
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field label="Nama Cabang" v-model="cabang.nama_cabang" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-header>Aktif</v-header>
              <v-radio-group v-model="cabang.aktif" column>
                <v-radio label="Ya" value="Y" color="info"></v-radio>
                <v-radio label="Tidak" value="T" color="error"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Save </v-btn>

              <router-link :to="{ name: 'cabang-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiDelete,
  mdiGreasePencil,
  mdiContentSave,
  mdiSlashForward,
} from '@mdi/js'

export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/superadmin/dashboard',
        },
        {
          text: 'Jenis Barang',
          disabled: false,
          href: '/superadmin/jenisbarang',
        },
        {
          text: 'Create Jenis Barang',
          disabled: true,
        },
      ],

      // icons
      icons: {
        mdiGreasePencil,
        mdiDelete,
        mdiContentSave,
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiSlashForward,
      },

      cabang: {
        nama_cabang: '',
        aktif: 'Y',
      },
      errors: [],
    }
  },
  mounted() {
    this.$isLoading(true)
    setTimeout(() => {
      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    CreateData(e) {
      if (this.$data.cabang.nama_cabang.length === 0) {
        // this.errors.push('Deskripsi harus diisi !')

        this.$swal.fire({
          title: 'Error',
          text: 'Nama cabang wajib diisi !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        let uri = process.env.VUE_APP_ROOT_API + '/api/superadmin/cabang/store'
        this.axios
          .post(uri, this.cabang)
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Cabang created successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 2000,
            })
          })
          .catch(e => {
            // console.log(e);
            this.$swal.fire({
              title: 'Error!',
              text: 'Cabang failed to create!',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })
          })

        e.preventDefault()
        setTimeout(() => {
          this.$router.push({ name: 'cabang-index' })
        }, 3000)
      }
    },
  },
}
</script>
